<template>
    <div>
        <div class="re-container">
            <audio ref="audio">
                <source :src="src" />
            </audio>
            <div class="unit-bar">
                <div class="bar-l">
                    <i class="fa fa-book"></i>
                    <template v-if="studyType == 1">
                        <span>{{ course.name }}-</span>-<span>{{ danyuan.name }}</span>
                    </template>
                    <span v-if="studyType == 2">单词复习中</span>
                    <span v-if="studyType == 3">错词学习中</span>
                </div>
                <div class="bar-r">
                    <i class="audio" ref="audioImg" @click="audioControl"></i>
                    <i class="iconfont icon-tuichu2" @click="emitClose(type)"></i>
                </div>
            </div>
            <div class="unit-bar__progress">
                <el-progress :percentage="processSpeed" :show-text="textShow"></el-progress>
                <img class="sport-img1" src="@/assets/images/study/sport.png" alt=""
                    style="width: 40px; height: 40px; left: 0" />
            </div>
            <div class="re-body" :class="[xuei_step_arr[xuexi_step] == 0 && last_page_state == 0 ? 'show' : 'hide']">
                <div class="re-ready">
                    <div class="re-text">
                        <span class="item-dot">●</span>
                        <span>即将开始学习, let's go</span>
                    </div>
                </div>
                <el-button class="rem-btn" type="primary" @click="start">开始学习
                </el-button>
            </div>
            <div class="re-body" :class="[xuei_step_arr[xuexi_step] == 8 && last_page_state == 0 ? 'show' : 'hide']">
                <div class="re-ready">
                    <div class="re-text">
                        <span class="item-dot">●</span>
                        <span>进入下一组,继续学习，go!go!go!</span>
                    </div>
                </div>
                <el-button class="rem-btn" type="primary" @click="start">继续学习
                </el-button>
            </div>

            <div class="re-body" :class="[xuei_step_arr[xuexi_step] == 1 && last_page_state == 0 ? 'show' : 'hide']">
                <div class="re-detail">
                    <div class="d-symbols">
                        <!--                        <span class="s-word">{{ now_words.name }}</span>-->
                        <!--                        <span class="s-syllable" v-if="now_words.ipa"-->
                        <!--                        >[<span s-syllable-info>{{ now_words.ipa }}</span-->
                        <!--                        >]</span-->
                        <!--                        >-->
                        <i class="fa fa-volume-up" @click="audioPlay_3(now_words.audio_link, now_words.id)"></i>
                        <span class="s-text">分音节:</span>
                        <div class="s-part">
                            <span class="p-01 p-01-color">{{ now_words.fenyinjie }}</span>
                            <span class="p-02 p-02-color"></span>
                        </div>
                    </div>
                    <div class="d-content">
                        <div class="d-c-detail">
                            <p class="d-c-english">{{ now_words.name }}</p>
                            <!--<img src="@/assets/images/study/yupao.jpg" alt="">-->
                            <!-- 幼儿图片 -->
                            <div class="d-img">
                                <img :src="audioUrl + now_words.img_link" alt="" @error="imgError" />
                                <!--<span class="d-img__text">{{ now_words.name }}</span>-->
                            </div>
                        </div>
                        <div class="d-example">
                            <p class="em-English" v-html="now_sentence.info"></p>
                            <p class="em-Chinese">{{ now_sentence.chinese }}</p>
                            <el-button class="text-btn" type="text" @click="nextSence()">下一句
                            </el-button>
                        </div>
                        <div class="d-number">
                            <div class="d-n-learn nLearn" v-show="du_num == 2">
                                <span class="l-num">{{ du_num }}</span>
                                <i class="fa fa-circle-thin"></i>
                            </div>
                            <div class="d-n-learn-1 nLearn1" v-show="du_num == 1">
                                <span class="l-num">{{ du_num }}</span>
                                <i class="fa fa-circle-thin"></i>
                            </div>
                            <div class="faArr" v-show="du_num <= 0" @click="fadeIns('.li-body-content')">
                                <i class="fa fa-arrow-circle-right"></i>
                            </div>
                            <p class="d-n-word">两遍英文一遍中文</p>
                        </div>
                    </div>
                </div>
            </div>
            <!--取消-->
            <div class="li-body" :class="[xuei_step_arr[xuexi_step] == 9 && last_page_state == 0 ? 'show' : 'hide']">
                <div class="li-body-detail">
                    <div class="li-title">
                        <span class="li-title-text">请听读音</span>
                        <i class="fa fa-volume-up" @click="audioPlay_3(now_words.audio_link, now_words.id)"></i>
                    </div>
                    <ul class="li-body-content" @click="removeAnimate('.li-body-content')">
                        <div v-if="now_words_zyy_daan == 2 || now_words_zyy_daan == 1">
                            <li class="li-body-content-info" v-for="(item, index) in now_words.shiyi_zyy" :key="index">
                                <label>
                                    <div class="input-box">
                                        <input type="radio" name="fruit" id="" value="" :checked="item.is_xz"
                                            disabled />
                                        <i class="i-radio raCheck"></i>
                                    </div>
                                    <div :class="[
                                        item.is_true == 1 ? 'bg-success text-white' : '',
                                        item.is_xz == 1 ? 'failBg text-white' : '',
                                        'choose',
                                    ]">
                                        <span class="choose-item"><span class="choose-item-text">
                                                <span class="fa fa-volume-up"
                                                    @click="audioPlay_3(item.audio_link, item.id)"></span>
                                            </span></span>
                                        <span class="choose-answer"></span>
                                        <i :class="[
                                            item.is_true == 1 ? 'fa-check' : 'fa-close',
                                            'fa',
                                        ]"></i>
                                    </div>
                                </label>
                            </li>
                        </div>
                        <template v-if="now_words_zyy_daan == 0">
                            <li class="li-body-content-info" v-for="(item, index) in now_words.shiyi_zyy" :key="index">
                                <input type="radio" name="fruit" id="" @click="checkItemZyy(item, index)"
                                    :checked="item.is_xz" />
                                <label>
                                    <div class="choose">
                                        <span class="choose-item">
                                            <span class="choose-item-text">
                                                <span class="fa fa-volume-up"
                                                    @click="audioPlay_3(item.audio_link, item.id)"></span>
                                            </span>
                                        </span>
                                        <span class="choose-answer">
                                        </span>
                                    </div>
                                </label>
                            </li>
                        </template>
                    </ul>
                    <!-- 幼儿暂时取消 -->
                    <ul class="li-body-content baby-read" v-if="nianji_level == 100">
                        <template v-if="now_words_zyy_daan == 2 || now_words_zyy_daan == 1">
                            <li class="li-body-content__info" v-for="(item, index) in now_words.shiyi_zyy" :key="index">
                                <label>
                                    <img :src="audioUrl + item.img_link" alt="" :class="[
                                        item.is_xz == 1 && item.is_true == 1 ? 'baby-checked' : '', item.is_xz == 1 && item.is_true == 0 ? 'baby-error' : ''
                                    ]" @error="imgError" />
                                    <div class="baby-item">
                                        <!--                      <input type="radio" name="fruit" id="" value="" disabled :checked="item.is_xz"/>-->
                                        <div class="input-box">
                                            <input type="radio" name="fruit" id="" value="" :checked="item.is_xz"
                                                disabled />
                                            <i class="i-radio"></i>
                                        </div>
                                        <div class="choose">
                                            <span class="choose-item">
                                                <span class="choose-item-text"></span>
                                            </span>
                                            <span class="choose-answer">{{ item.text }}</span>
                                        </div>
                                    </div>
                                </label>
                                <i class="fa fa-check" v-if="item.is_true == 1"></i>
                                <i class="fa fa-close" v-if="item.is_true == 0 && item.is_xz == 1"></i>
                            </li>
                        </template>

                        <template v-if="now_words_zyy_daan == 0">
                            <li class="li-body-content__info" v-for="(item, index) in now_words.shiyi_zyy" :key="index">
                                <label>
                                    <img :src="audioUrl + item.img_link" alt="" @error="imgError" />
                                    <div class="baby-item">
                                        <input type="radio" name="fruit" id="" value=""
                                            @click="checkItemZyy(item, index)" :checked="item.is_xz" />
                                        <div class="choose">
                                            <span class="choose-item">
                                                <span class="choose-item-text"></span>
                                            </span>
                                            <span class="choose-answer">{{ item.text }}</span>
                                        </div>
                                    </div>
                                </label>
                            </li>
                        </template>
                    </ul>
                    <div class="li-listen nextArr" v-show="now_words_zyy_daan == 1">
                        <i class="fa fa-arrow-circle-right" @click="nextOption"></i>
                    </div>
                    <div class="li-listen" v-show="now_words_zyy_daan == 0">
                        <i class="fa fa-frown-o emojis"></i>
                    </div>
                    <div class="li-listen" v-if="now_words_zyy_daan == 2">
                        <div class="li-practice" @click="clkBeiYiBei">
                            <i class="fa fa-circle-thin"></i>
                            <span>练</span>
                        </div>
                        <p class="li-re">背一背</p>
                    </div>
                </div>
                <div class="read-countdown-time">
                    <i class="fa fa-sun-o time-icon"></i>
                    <span class="custom-textshadow">{{ djs_count }}</span>
                </div>
            </div>
            <div class="li-body" :class="[xuei_step_arr[xuexi_step] == 3 && last_page_state == 0 ? 'show' : 'hide']">
                <div class="li-body-detail">
                    <div class="li-title">
                        <span class="li-title-text">请听读音</span>
                        <i class="fa fa-volume-up" @click="audioPlay_3(now_words.audio_link, now_words.id)"></i>
                    </div>
                    <ul class="li-body-content" @click="removeAnimate('.li-body-content')">
                        <div v-if="now_words_zyy_daan == 2 || now_words_zyy_daan == 1">
                            <li class="li-body-content-info" v-for="(item, index) in now_words.shiyi_zyy" :key="index">
                                <label>
                                    <div class="input-box">
                                        <input type="radio" name="fruit" id="" value="" :checked="item.is_xz"
                                            disabled />
                                        <i class="i-radio raCheck"></i>
                                    </div>
                                    <div :class="[
                                        item.is_true == 1 ? 'bg-success text-white' : '',
                                        item.is_xz == 1 ? 'failBg text-white' : '',
                                        'choose',
                                    ]">
                                        <span class="choose-item"><span class="choose-item-text">{{
                                                item.text
                                        }}</span></span>
                                        <span class="choose-answer"></span>
                                        <i :class="[
                                            item.is_true == 1 ? 'fa-check' : 'fa-close',
                                            'fa',
                                        ]"></i>
                                    </div>
                                </label>
                            </li>
                        </div>
                        <template v-if="now_words_zyy_daan == 0">
                            <li class="li-body-content-info" v-for="(item, index) in now_words.shiyi_zyy" :key="index">
                                <label>
                                    <input type="radio" name="fruit" id="" @click="checkItemZyy(item, index)"
                                        :checked="item.is_xz" />
                                    <div class="choose">
                                        <span class="choose-item"><span class="choose-item-text">{{
                                                item.text
                                        }}</span></span>
                                        <span class="choose-answer"></span>
                                    </div>
                                </label>
                            </li>
                        </template>
                    </ul>
                    <!-- 幼儿暂时取消 -->
                    <ul class="li-body-content baby-read" v-if="nianji_level == 100">
                        <template v-if="now_words_zyy_daan == 2 || now_words_zyy_daan == 1">
                            <li class="li-body-content__info" v-for="(item, index) in now_words.shiyi_zyy" :key="index">
                                <label>
                                    <img :src="audioUrl + item.img_link" alt="" :class="[
                                        item.is_xz == 1 && item.is_true == 1 ? 'baby-checked' : '', item.is_xz == 1 && item.is_true == 0 ? 'baby-error' : ''
                                    ]" @error="imgError" />
                                    <div class="baby-item">
                                        <!--                      <input type="radio" name="fruit" id="" value="" disabled :checked="item.is_xz"/>-->
                                        <div class="input-box">
                                            <input type="radio" name="fruit" id="" value="" :checked="item.is_xz"
                                                disabled />
                                            <i class="i-radio"></i>
                                        </div>
                                        <div class="choose">
                                            <span class="choose-item">
                                                <span class="choose-item-text"></span>
                                            </span>
                                            <span class="choose-answer">{{ item.text }}</span>
                                        </div>
                                    </div>
                                </label>
                                <i class="fa fa-check" v-if="item.is_true == 1"></i>
                                <i class="fa fa-close" v-if="item.is_true == 0 && item.is_xz == 1"></i>
                            </li>
                        </template>

                        <template v-if="now_words_zyy_daan == 0">
                            <li class="li-body-content__info" v-for="(item, index) in now_words.shiyi_zyy" :key="index">
                                <label>
                                    <img :src="audioUrl + item.img_link" alt="" @error="imgError" />
                                    <div class="baby-item">
                                        <input type="radio" name="fruit" id="" value=""
                                            @click="checkItemZyy(item, index)" :checked="item.is_xz" />
                                        <div class="choose">
                                            <span class="choose-item">
                                                <span class="choose-item-text"></span>
                                            </span>
                                            <span class="choose-answer">{{ item.text }}</span>
                                        </div>
                                    </div>
                                </label>
                            </li>
                        </template>
                    </ul>
                    <div class="li-listen nextArr" v-show="now_words_zyy_daan == 1">
                        <i class="fa fa-arrow-circle-right" @click="nextOption"></i>
                    </div>
                    <div class="li-listen" v-show="now_words_zyy_daan == 0">
                        <i class="fa fa-frown-o emojis"></i>
                    </div>
                    <div class="li-listen" v-if="now_words_zyy_daan == 2">
                        <div class="li-practice" @click="clkBeiYiBei">
                            <i class="fa fa-circle-thin"></i>
                            <span>练</span>
                        </div>
                        <p class="li-re">背一背</p>
                    </div>
                </div>
                <div class="read-countdown-time">
                    <i class="fa fa-sun-o time-icon"></i>
                    <span class="custom-textshadow">{{ djs_count }}</span>
                </div>
            </div>
            <div class="re-body memory"
                :class="[xuei_step_arr[xuexi_step] == 4 && last_page_state == 0 ? 'show' : 'hide']">
                <div class="memory-body">
                    <div class="memoryBox" v-for="(item, index) in allLists" :key="index"
                        v-show="huiyi_show_index > index" ref="con" @click="audioPlay_3(item.audio_link, item.id)">
                        <div class="memoryContainer">
                            <div class="memory-body-item english">
                                <span class="memory-body-item-word">{{ item.name }}</span>
                            </div>
                            <div class="memory-body-item chinese">
                                <span class="memory-body-item-word">{{ item.analysis }}</span>
                            </div>
                        </div>
                        <i class="fa fa-volume-up" @click.stop="audioPlay_3(item.audio_link, item.id)" title="发音"></i>
                    </div>
                </div>
                <el-button class="exitBtn" @click="emitClose(type)">Exit</el-button>
            </div>
            <div class="summary" :class="[last_page_state == 1 ? 'show' : 'hide']">
                <div class="summary-body">
                    <div class="summary-body-l">
                        <div class="summary-list__content">
                            <div class="content-item">
                                <span class="dot">●</span>
                                <span class="item-text">单词总数（个）：</span>
                                <span class="item-num">{{ wordsLists.length }}</span>
                            </div>
                            <div class="content-item">
                                <span class="dot">●</span>
                                <span class="item-text">未完成数（个）：</span>
                                <span class="item-num word-num">{{
                                        wordsLists.length - rightCount
                                }}</span>
                            </div>
                            <div class="content-item">
                                <span class="dot">●</span>
                                <span class="item-text">本次学习（个）：</span>
                                <span class="item-num word-num">{{ rightCount }}</span>
                            </div>
                        </div>
                        <div class="summary-list__content">
                            <div class="content-item">
                                <span class="dot">●</span>
                                <span class="item-text" v-if="studyType == 1">完成本单元可获</span>
                                <span class="item-text" v-else>保存后可获</span>
                                <span class="item-num word-num gain-num">{{ baoshi_num }}</span>
                                <i class="iconfont icon-zuanshi"></i>
                            </div>
                        </div>
                        <div class="summary-list__content" v-if="studyType == 1">
                            <div class="content-item">
                                <span class="dot">●</span>
                                <span>继续学习可获更多宝石</span>
                            </div>
                        </div>
                    </div>
                    <div class="summary-body-r">
                        <div class="r-logo">
                            <div class="test-logo"></div>
                            <span>X</span>
                            <span>{{ baoshi_num }}</span>
                        </div>
                        <div class="btn-box">
                            <el-button class="btn-test" v-if="rightCount < daan_lists.length" @click="jixuXuexi">继续学习
                            </el-button>
                            <el-button class="btn-test" v-if="
                                rightCount >= daan_lists.length &&
                                studyType == 2 &&
                                reviewType == 2 &&
                                nextFuxiType.count > 0
                            " @click="jixuFuxi">继续复习“{{ nextFuxiType.name }}”环节
                            </el-button>

                            <el-button class="btn-test" v-if="rightCount >= daan_lists.length && studyType == 1"
                                @click="ceshiDo">单元测试
                            </el-button>
                            <!--<el-button class="btn-test">单元测试</el-button>-->
                            <el-button class="btn-exit" @click="addData">保存退出</el-button>
                        </div>
                    </div>
                </div>
            </div>
            <!-- <lMemory style="display: none" /> -->
            <div class="re-footer">
                <span class="warm-tips"
                    style="fontsize: 12px; color: #777; padding-left: 15px">【温馨提示：点击“CTRL”键再播放一遍，点击回车键到下一步】</span>
                <div class="time-total">
                    <!--<span class="t-text">记忆时间</span
                              ><span class="t-time"
                                >[ <span class="t-time-text">00:03:41</span>]</span
                              >-->
                    <span class="t-current">当前生词数</span>
                    <span class="t-num">[<span class="t-num-text">{{ wordNum }}</span>]个</span>
                    <span class="t-current">共</span>
                    <span class="t-num">[<span class="t-num-text">{{ all_lun }}</span>]轮</span>
                    <span class="t-shibie">当前第</span><span class="t-total">[<span class="t-total-text">{{ now_lun
                    }}</span>]轮</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import $ from "jquery";
import { mapMutations, mapState } from "vuex";
import { imgError, nianjiLevel } from "@/utils/utils";

export default {
    name: "aiListen",
    props: {
        title: { type: String },
        courseTitle: { type: String },
        audioUrl: { type: String },
        course: { type: Object },
        danyuan: { type: Object },
        wordsLists: { type: Array },
        allLists: { type: Array, default: [] },
        studyType: { type: Number, default: 1 }, //类型，1学习，2复习，3错词
        reviewType: { type: Number, default: 1 }, //复习类型，1复习，2一键复习
        nextFuxiType: { type: Object }, //下一个复习类型
    },
    data() {
        return {
            memory: "memory-body-item-container",
            flag: 0,
            convers: "convers",
            classShow: [],
            show_index: 0,
            textShow: false,
            isActive: 0,
            xuexi_step: 0, //学习步骤,0开始学习，1读，2单选，3多选
            xuexi_step_old: 0, //记录上一个学习步骤，默认到步骤1，单选环节
            last_page_state: 0,//是否显示最后环节
            xuei_step_arr: [0, 1, 2, 3, 4, 5, 6, 7, 8],
            du_num: 2, //读环节的读次数
            leter_serial: ["A", "B", "C", "D", "D"],
            study_mk: 0, //模块
            lun_num: 10, //每轮多少个
            all_lun: 0, //总共多少轮
            now_lun: 1, //当前第几轮
            now_words: {}, //当前单词
            now_words_index: 0, //当前单词索引（所有单词）
            now_lun_words_index: 0, //当前轮单词的索引
            now_words_daan: 0, //当前答案是否正确，默认0，1正确，2错误
            now_words_zyy_daan: 0, //中译英，当前答案是否正确，默认0，1正确，2错误
            now_words_yyz_daan: 0, //英译中，当前答案是否正确，默认0，1正确，2错误
            now_lun_count: 0, //当前轮多少个
            now_lun_finish_count: 0,
            process_c: 0, //进度条
            lun_lists_id_arr: [], //当前轮lists
            error_lists_arr: [], //错误单词
            now_sentence: { info: "", chinese: "" }, //当前例句
            now_sentence_index: 0, //当前例句索引
            daan_lists: [], //答案列表
            djs_obj: 1, //倒计时对象
            djs_count: 5, //答错后倒计时5秒
            djs_state: 1, //倒计时状态
            djs_stop: 0,
            audio_obj: [],
            start_time: 0,
            end_time: 0,
            use_time: 0,
            now_daan: "",
            action_status: 1, //防止提交多次
            type: 2, //学习类型，2词听
            mode_type: 1,
            is_goto_test: 0, //是否跳转到测试页
            huiyi_show_index: 0,
            menuList: [
                { id: 1, mText: "记忆夹生词（个）：", mNum: 0 },
                { id: 2, mText: "拼写夹生词（个）：", mNum: 1 },
                { id: 3, mText: "连续识别错误（个）：", mNum: 0 },
                { id: 4, mText: "连续拼写错误（个）：", mNum: 1 },
            ],
            chooseAudios: {
                src: require("../../assets/audio/check.wav"),
                errSrc: require("../../assets/audio/error.mp3"),
            },
            playState: 1,
            src: require("../../assets/audio/Drip.mp3"),
            audioImg: {
                play: require("../../assets/images/study/exitmusic03.png"),
                paused: require("../../assets/images/study/exitmusic01.png"),
            },
            key_state: 0, //快捷键未按下
            d_code: "",
            is_enter: 1,//快捷键是否允许回车，1是允许回车，0不允许
            baoshi_num: 0,
            get_baoshi_type: 1,//获取宝石类型，1学习，3复习
            nianji_level: 0,//年纪等级，0默认，1少儿
            imgError: null
        };
    },
    created() {
        this.imgError = imgError;
        this.nianji_level = nianjiLevel(this.user, this.course, this.studyType);
        //console.log("nianji_level:",this.nianji_level);
        this.getDjsCount();

        this.initData();
        if (this.studyType == 1) {
            this.get_baoshi_type = 1;
            this.getBaoshi();
        } else if (this.studyType == 2) {
            this.get_baoshi_type = 3;
            this.getBaoshi();
        }

        // let dContent = document.querySelectorAll('.li-body-content');
        // for (let i = 0; i < dContent.length; i++) {
        //     dContent[i].classList.add('fadeIns');
        // }
    },
    updated() {
        //console.log(this.processSpeed);
        let sportImg1 = document.querySelector(".sport-img1");
        //console.log(sportImg1.style.left);
        if (this.processSpeed === 0) {
            sportImg1.style.left = 0;
        } else if (this.processSpeed === 100) {
            sportImg1.style.left = 97 + "%";
        } else {
            sportImg1.style.left = this.processSpeed - 3 + "%";
        }
        // 判断中英文，调整字号
        let cItem = document.querySelectorAll(".choose-item-text");
        let reg = /[\u4e00-\u9fa5]/;
        // let reg = /[A-z]/;
        for (let i = 0; i < cItem.length; i++) {
            if (reg.test(cItem[i].innerHTML)) {
                cItem[i].style.fontSize = ".20rem";
            } else {
                cItem[i].style.fontSize = ".24rem";
            }
        }
    },
    computed: {
        ...mapState({
            token: (state) => state.user.token,
            user: (state) => state.user.user,
        }),
        rightCount() {
            let right_count = 0;
            this.daan_lists.forEach((item, index) => {
                if (item.answer == 1) {
                    right_count++;
                }
            });
            return right_count;
        },
        errorCount() { },
        noCount() { },
        processSpeed() {
            let a = 0;
            if (this.xuei_step_arr[this.xuexi_step] == 4) {
                a = (this.huiyi_show_index / this.allLists.length) * 100;
            } else {
                a = (this.now_lun_finish_count / this.now_lun_count) * 100;
                //console.log("now_lun_finish_count:" + this.now_lun_finish_count);
                //console.log("now_lun_count:" + this.now_lun_count);
            }
            //console.log("a:" + a);
            return a > 100 ? 100 : a;
        },
        wordNum() {
            return this.wordsLists.length;
        },
    },
    mounted: function () {
        this.changeVolume();
        var that = this;
        $(".memoryBox")
            .click(function () {
                var index = $(".memoryBox").index(this);
                //console.log(index);
                if ($(".memoryContainer").eq(index).hasClass("convers")) {
                    $(".memoryContainer").eq(index).removeClass("convers");
                } else {
                    $(".memoryContainer").eq(index).addClass("convers");
                }
            })
            .mouseenter(function () {
                var vIndex = $(".memoryBox").index(this);
                //console.log(vIndex);
                $(".memoryBox .fa").eq(vIndex).css("display", "block");
            })
            .mouseleave(function () {
                var lIndex = $(".memoryBox").index(this);
                $(".memoryBox .fa").eq(lIndex).css("display", "none");
            });
        // 右箭头 下一步
        this.animations(".faArr", () => {
            that.duNext();
        });
        // 右箭头 下一题
        // this.animations('.nextArr', ()=>{
        //   that.nextOption();
        // })
        // 2, 1点击
        this.animations(".nLearn", () => {
            that.clkDuNum();
        });
        this.animations(".nLearn1", () => {
            that.clkDuNum();
        });
        // 哭脸
        this.animations(".emojis", () => {
            that.forget();
        });

        //键盘事件
        /*var key_state = 0; //未按下
        var d_code = "";
        $(document).keydown(function (event) {
          if (key_state != 1) {
            key_state = 1;
            d_code = event.keyCode;
          }
        });
        $(document).keyup(function (event) {
          if (event.keyCode == d_code) {
            key_state = 0;
            d_code = "";
          }
          //console.log("event");
          //console.log(event.keyCode);
          that.key_event(event.keyCode, d_code);
        });*/
        // 添加 resize 绑定事件
        window.addEventListener(
            "keyup", this.key_up, false
        );
        window.addEventListener(
            "keydown", this.key_down, false
        );
    },
    methods: {
        // 背景音乐
        audioControl() {
            let audioImg = this.$refs.audioImg;
            let audio = this.$refs.audio;
            if (this.playState === 0) {
                audioImg.style.backgroundImage = "url(" + this.audioImg.paused + ")";
                audio.pause();
                this.playState = 1;
            } else {
                audioImg.style.backgroundImage = "url(" + this.audioImg.play + ")";
                audio.play();
                this.playState = 0;
            }
        },
        // 播放器音量
        changeVolume() {
            let audio = this.$refs.audio;
            audio.volume = 0.3;
        },
        chooseAudio(type = 1) {
            let audio = new Audio();
            audio.controls = false;
            let src = this.chooseAudios.src;
            if (type === 0) {
                src = this.chooseAudios.errSrc;
            }
            audio.src = src;
            audio.play();
        },
        // 动画结束前禁止点击
        animations(arg, fc) {
            let fa = document.querySelector(arg); //'.faArrow'
            fa.addEventListener("animationstart", function () {
                this.style.cursor = "normal";
                //console.log("动画开始");
                this.onclick = function () {
                    return false;
                };
            });
            fa.addEventListener("animationend", function () {
                this.style.cursor = "pointer";
                //console.log("动画结束");
                this.onclick = function () {
                    fc();
                };
            });
        },
        fadeIns(arg) {
            let dContent = document.querySelector(arg);
            if (dContent != null) {
                dContent.classList.add("fadeIns");
                //console.log(dContent.classList);
            }
        },
        removeAnimate(arg) {
            let dContent = document.querySelector(arg);
            if (dContent != null) {
                dContent.classList.remove("fadeIns");
                //console.log(dContent.classList);
            }
        },
        initData() {
            //重新初始化数据
            /*this.du_num = 2;
                  this.xuexi_step = 0;
                  this.xuexi_step_old = 0;
                  this.now_words_zyy_daan = 0;
                  this.now_lun = 1;
                  this.now_words = {};
                  this.now_words_index = 0;
                  this.now_lun_words_index = 0;
                  this.now_words_daan = 0;
                  this.now_lun_count = 0;
                  this.now_lun_finish_count = 0;
                  this.process_c = 0;
                  this.lun_lists_id_arr = [];
                  this.error_lists_arr = [];
                  this.daan_lists = [];
                  this.djs_stop = 0;
                  this.djs_state = 1;
                  this.action_status = 1;
                  this.is_goto_test = 0;*/
            this.setLunListsId(this.now_lun);
            this.randNowWord();
            ////console.log("now_words");
            ////console.log(this.now_words);
            //this.studyWords(this.now_words_index);
            //use_time_sep 添加使用时间，判断是否第一次添加
            this.daan_lists = this.wordsLists.map((item, index, arr) => {
                let temp_item = {
                    id: item.id,
                    name: item.name,
                    type: this.type,
                    mode_type: this.mode_type,
                    answer: 0,
                    levels: 0,
                    right_num: 0,
                    error_num: 0,
                    use_time: 0,
                    use_time_sep: 0,
                    use_time_yuxi: 0,
                    huanjie: "",
                    error_type: "",
                    study_type: this.studyType,
                    jilu: [],
                    score: [],
                    is_xuexi: 0,
                };
                return temp_item;
            });


            //因为学习环节取消了预习，暂时注释掉
            /*if (this.studyType == 2 && this.nextFuxiType.last_type >-1){
                //复习
                this.xuexi_step = 2;
            }*/

        },
        start() {

            //因为学习环节取消了预习，暂时注释掉
            if (this.studyType == 1 || this.studyType == 3) {

                if (this.user.is_yuxi == 0) {//关闭预习
                    this.xuexi_step = 3;
                    // this.setDjs(0);
                } else {
                    this.xuexi_step = 1;
                }
            } else {
                this.xuexi_step = 3;
                // this.setDjs(0);
            }
            this.randNowWord();
        },
        clkDuNum() {
            this.removeAnimate(".d-content");
            if (this.du_num > 0) {
                this.audioPlay_3(this.now_words.audio_link, this.now_words.id);
                this.du_num--;
            }
        },
        duNext() {
            this.fadeIns(".d-content");
            let step_old = this.xuexi_step_old;
            //console.log("step_old" + step_old);
            this.start_time = this.getTime(); //开始时间
            if (step_old == 0) {
                step_old = 1;
            }
            switch (step_old) {
                case 0: //开始学习
                    //this.xuexi_step = 0;
                    break;
                case 1: //读
                    //this.xuexi_step = 1;
                    this.delLunListsWord(this.now_lun_words_index);
                    this.addProgress();
                    if (this.nextWords()) {
                        this.du_num = 2;
                    } else {
                        //进入下一环节
                        this.xuexi_step = 3;//取消2，直接跳转到3
                        this.nextLun(this.now_lun);
                        this.randNowWord();
                        // this.setDjs(0);
                    }
                    break;
                case 2: //英语单选
                    this.xuexi_step = 2;
                    this.randNowWord();
                    // this.setDjs(0);
                    break;
                case 3: //单选中文
                    this.randNowWord();
                    // this.setDjs(0);
                    this.xuexi_step = 3;
                    break;
                case 4: //复习
                    this.xuexi_step = 4;
                    break;
                case 5: //查看结果
                    this.xuexi_step = 5;
                    break;
                default:
                    break;
            }
        },
        nextOption() {
            this.fadeIns(".li-body-content");
            this.fadeIns(".chineseCon");
            this.removeAnimate(".d-content");
            //正确到下一个单词
            let step = this.xuexi_step;
            this.start_time = this.getTime(); //开始时间
            switch (step) {
                case 0: //开始学习
                    break;
                case 1: //读
                    break;
                case 2: //英语单选
                    //console.log("英语单选");
                    this.delErrorWords();
                    //console.log(this.error_lists_arr);
                    if (!this.hasErrorWords()) {
                        //不在错误单词中，才可以删除
                        this.delLunListsWord(this.now_lun_words_index); //刪除正確的單詞
                        //console.log("hasErrorWords---01");
                    } else {
                        //console.log("hasErrorWords---02");
                        //console.log(this.hasErrorWords());
                    }
                    this.now_words_zyy_daan = 0;
                    if (this.nextWords()) {
                        // this.setDjs(0);
                    } else {
                        //进入下一环节
                        this.xuexi_step = 3;
                        //this.now_lun = 1;
                        this.nextLun(this.now_lun);
                        this.randNowWord();
                    }
                    break;
                case 3: //单选中文
                    //console.log("英语单选");
                    this.delErrorWords();
                    if (!this.hasErrorWords()) {
                        //不在错误单词中，才可以删除
                        this.delLunListsWord(this.now_lun_words_index); //刪除正確的單詞
                    }
                    this.now_words_zyy_daan = 0;
                    if (this.nextWords()) {
                        // this.setDjs(0);
                        //this.setStepOld();
                        //this.xuexi_step = 1;
                        //this.du_num = 2;
                    } else {
                        //进入下一环节
                        //this.xuexi_step = 3;
                        this.now_lun++;
                        if (this.nextLun(this.now_lun)) {
                            //this.randNowWord();
                            //this.xuexi_step_old = 1; //下一轮从第二环节开始学习
                            this.setStepOld(1); //下一轮从第二环节开始学习
                            //this.xuexi_step = 1;
                            //this.showNext(1);
                            this.xuexi_step = 8;
                        } else {
                            //所有单词学习完成
                            //console.log("所有单词学习完成");
                            if (this.studyType == 1) {
                                this.xuexi_step = 4;
                                this.huiyi_ing();
                            } else {
                                //this.xuexi_step = 5;
                                this.last_page_state = 1;
                            }
                            //this.xuexi_step = 4; //进入复习环节
                        }
                    }
                    break;
                case 4: //复习
                    break;
                case 5: //查看结果
                    break;
                default:
                    break;
            }
        },
        forget() {
            //忘记，不记得
            //console.log("forget");
            //console.log("this.xuexi_step:" + this.xuexi_step);
            let step = this.xuexi_step;
            this.djs_state = 0;
            switch (step) {
                case 0: //开始学习
                    break;
                case 1: //读
                    break;
                case 2: //英语单选
                    this.setStepOld();
                    this.xuexi_step = 1;
                    this.addErrorWords();
                    break;
                case 3: //单选中文
                    this.setStepOld();
                    this.du_num = 2;
                    this.xuexi_step = 1;
                    this.addErrorWords();
                    break;
                case 4: //复习
                    break;
                case 5: //查看结果
                    break;
                default:
                    break;
            }
        },
        randNowWord() {
            //随机当前学的单词
            let lun_lists_count = this.lun_lists_id_arr.length;
            let randNum = this.getRandomInt(0, lun_lists_count); //获取当前随机数
            this.now_words_index = this.lun_lists_id_arr[randNum];
            this.now_words = this.wordsLists[this.now_words_index];
            this.now_lun_words_index = randNum;
            this.resetNowWord(); //重置当前单词释义
            this.resetWordOrder(); //词义重新排序
            if (this.xuexi_step != 0 && this.xuexi_step != 8) {
                this.audioPlay_3(this.now_words.audio_link, this.now_words.id);
            }
            this.now_sentence_index = 0;
            if (this.now_words['duanyu'].length > 0) {
                this.now_sentence = this.now_words['duanyu'][this.now_sentence_index];
            } else {
                this.now_sentence = [];
            }
        },
        resetNowWord() {
            var shiyi = this.now_words.shiyi;
            for (var i = 0; i < shiyi.length; i++) {
                this.now_words.shiyi[i].is_xz = 0;
            }
            var cixing = this.now_words.cixing;
            for (var i = 0; i < cixing.length; i++) {
                this.now_words.cixing[i].is_xz = 0;
            }

            var shiyi_dx = this.now_words.shiyi_dx; //单选
            for (var i = 0; i < shiyi_dx.length; i++) {
                this.now_words.shiyi_dx[i].is_xz = 0;
            }
            var shiyi_zyy = this.now_words.shiyi_zyy; //中释英
            for (var i = 0; i < shiyi_zyy.length; i++) {
                this.now_words.shiyi_zyy[i].is_xz = 0;
            }
            console.log("resetNowWord");
            //this.resetWordOrder();
        },
        resetWordOrder() {
            //重新排序
            var shiyi = this.now_words.shiyi;
            var newarr = [];
            while (shiyi.length > 0) {
                var len = parseInt(Math.random() * shiyi.length);
                newarr.push(shiyi[len]);
                shiyi.splice(len, 1);
            }
            this.now_words.shiyi = newarr;

            //重新排序
            var shiyi_dx = this.now_words.shiyi_dx;
            let item = shiyi_dx.splice(shiyi_dx.length - 1, 1);
            var newarr02 = [];
            while (shiyi_dx.length > 0) {
                var len = parseInt(Math.random() * shiyi_dx.length);
                newarr02.push(shiyi_dx[len]);
                shiyi_dx.splice(len, 1);
            }
            newarr02.push(...item);
            this.now_words.shiyi_dx = newarr02;

            //重新排序
            var shiyi_zyy = this.now_words.shiyi_zyy;
            let item02 = shiyi_zyy.splice(shiyi_zyy.length - 1, 1);
            var newarr03 = [];
            while (shiyi_zyy.length > 0) {
                var len = parseInt(Math.random() * shiyi_zyy.length);
                newarr03.push(shiyi_zyy[len]);
                shiyi_zyy.splice(len, 1);
            }
            newarr03.push(...item02);
            this.now_words.shiyi_zyy = newarr03;
        },
        nextWords() {
            //console.log(this.all_lun + "----" + this.now_lun);
            if (this.lun_lists_id_arr.length <= 0) {
                return 0; //当前轮没有数据了
            } else {
                this.randNowWord();

                return 1;
            }
        },
        nextLun(now_lun) {
            this.now_lun_finish_count = 0;
            //下一轮
            if (now_lun > this.all_lun) {
                return 0; //没有下一轮了
            } else {
                this.setLunListsId(now_lun);
                return 1;
            }
        },
        clkBeiYiBei() {
            //背一背
            this.setStepOld();
            this.xuexi_step = 1;
            this.now_words_daan = 0;
            this.now_words_zyy_daan = 0;
            this.now_words_yyz_daan = 0;
            this.du_num = 2;
            //下一个环节重置单词
            this.resetNowWord();
        },

        nextSence() {
            //例句索引
            if (this.now_words['duanyu'].length - 1 > this.now_sentence_index) {
                this.now_sentence_index++;
            } else {
                this.now_sentence_index = 0;
            }
            if (this.now_words['duanyu'].length > 0) {
                this.now_sentence = this.now_words['duanyu'][this.now_sentence_index];
            }
        },
        setStepOld(id = 0) {
            switch (this.xuexi_step) {
                case 1:
                    this.xuexi_step_old = id ? id : 1;
                    break;
                case 2:
                    this.xuexi_step_old = id ? id : 2;
                    break;
                case 3:
                    this.xuexi_step_old = id ? id : 3;
                    break;
                case 4:
                    this.xuexi_step_old = id ? id : 4;
                    break;
                case 5:
                    this.xuexi_step_old = id ? id : 5;
                    break;
                default:
                    this.xuexi_step_old = 0;
            }
            this.du_num = 2;
        },
        setLunListsId(lun) {
            this.lun_lists_id_arr = [];
            //获取当前轮id
            let start_index = this.lun_num * (lun - 1);
            let end_index = this.lun_num * lun;
            let words_length = this.wordsLists.length;
            if (end_index > words_length) {
                end_index = words_length;
            }
            this.now_lun_count = end_index - start_index;
            this.all_lun = Math.ceil(words_length / this.lun_num);
            for (let index = start_index; index < end_index; index++) {
                this.lun_lists_id_arr.push(index);
            }
        },
        addProgress() {
            let err_word = this.hasErrorWords();
            if (err_word) {
                if (err_word.num == 1) {
                    this.now_lun_finish_count++; //进度条
                }
            } else {
                this.now_lun_finish_count++; //进度条
            }
        },
        checkItem(item, index) {
            //暂时没有使用（废弃）
            this.djs_state = 0;
            //单选
            this.now_words.shiyi[index].is_xz = 1;
            this.now_daan = this.now_words.shiyi[index].text;
            this.getDiffTime(); //设置使用时间
            if (item.is_true == 1) {
                this.chooseAudio();
                this.addProgress();

                //正确选择到下一步
                this.now_words_daan = 0;
                this.nextOption();
                this.addCaozuLog(1);
            } else {
                this.chooseAudio(0);
                this.now_words_daan = 2; //显示答案
                this.addCaozuLog(0);
                this.addErrorWords();
            }
        },
        checkItemZyy(item, index) {
            this.djs_state = 0;
            //中译英
            //this.xuexi_step++;
            this.now_words.shiyi_zyy[index].is_xz = 1;
            this.now_daan = this.now_words.shiyi_zyy[index].text;
            this.getDiffTime(); //设置使用时间
            this.addUseTime(1); //添加使用时间

            if (item.is_true == 1) {
                this.chooseAudio();
                this.addProgress();
                //正确选择到下一步
                this.now_words_zyy_daan = 1;
                this.addCaozuLog(1);
                let step = this.xuei_step_arr[this.xuexi_step];
                if(step == 3){
                    this.addRightAnswer(1); //添加正确答案
                }                
            } else {
                this.chooseAudio(0);
                this.now_words_zyy_daan = 2; //显示答案
                this.addCaozuLog(0);
                this.addErrorWords();
            }
        },
        checkItemYyz(item, index) {
            this.djs_state = 0;
            // this.getDiffTime(); //设置使用时间
            // this.addUseTime(2); //添加使用时间
            //英译中
            //this.xuexi_step++;
            this.now_words.shiyi_dx[index].is_xz = 1;
            if (item.is_true == 1) {
                this.chooseAudio();
                //this.now_lun_finish_count++;
                this.addProgress();
                //正确选择到下一步
                this.now_words_yyz_daan = 1;
                //this.nextOption();
                this.addRightAnswer(1); //添加正确答案
                this.addCaozuLog(1);
            } else {
                this.chooseAudio(0);
                this.now_words_yyz_daan = 2; //显示答案
                this.addCaozuLog(0);
                this.addErrorWords();
            }
        },
        addErrorWords() {
            //添加错误单词
            //console.log("err_words");
            let err_words = {
                id: this.now_words_index,
                word_id: this.now_words.id,
                num: 2,
                error_num: 1,
            };
            let err_index = -1;
            let error_lists = this.error_lists_arr;
            for (let i = 0; i < error_lists.length; i++) {
                if (error_lists[i] && error_lists[i].word_id == this.now_words.id) {
                    err_words = error_lists[i];
                    err_index = i;
                    break;
                }
            }
            if (err_index >= 0) {
                err_words.num = 2;
                err_words.error_num = err_words.error_num + 1; //错误次数
                this.error_lists_arr[err_index] = err_words;
            } else {
                this.error_lists_arr.push(err_words);
            }
            //console.log("error_lists_arr");
            //console.log(this.error_lists_arr);
        },
        delLunListsWord(now_lun_words_index) {
            //删除当前轮里能过的单词
            //console.log("delLunListsWord-now_lun_words_index");
            //console.log(now_lun_words_index);
            this.lun_lists_id_arr.splice(now_lun_words_index, 1);
            //console.log(this.lun_lists_id_arr);
        },
        delErrorWords() {
            //消除错误单词
            //this.error_lists_arr.splice(this.now_words_index,1);
            let error_lists = this.error_lists_arr;
            let error_index = -1;
            let err_words = "";
            for (let i = 0; i < error_lists.length; i++) {
                if (error_lists[i].word_id == this.now_words.id) {
                    err_words = error_lists[i];
                    error_index = i;
                    break;
                }
            }
            //console.log("delErrorWords");
            //console.log(err_words);
            if (err_words) {
                err_words.num = err_words.num - 1;
                if (err_words.num <= 0) {
                    this.error_lists_arr.splice(error_index, 1);
                    //console.log("delErrorWords:1");
                    return 1;
                } else {
                    //console.log("delErrorWords:2");
                    return 0;
                }
            } else {
                //console.log("delErrorWords:3");
                return 1;
            }
        },
        hasErrorWords() {
            let err_words = "";
            let error_lists = this.error_lists_arr;
            for (let i = 0; i < error_lists.length; i++) {
                if (error_lists[i].word_id == this.now_words.id) {
                    err_words = error_lists[i];
                    break;
                }
            }
            if (err_words) {
                return err_words;
            } else {
                return false;
            }
        },
        player() {
            //console.log("player");
        },
        unique(array) {
            //去重复
            var n = {},
                r = [],
                len = array.length,
                val,
                type;
            for (var i = 0; i < array.length; i++) {
                val = array[i];
                type = typeof val;
                if (!n[val]) {
                    n[val] = [type];
                    r.push(val);
                } else if (n[val].indexOf(type) < 0) {
                    n[val].push(type);
                    r.push(val);
                }
            }
            return r;
        },
        addRightAnswer(answer) {
            //console.log("addRightAnswer");
            //添加正确答案
            //answer 1,正确，0错误
            let step = this.xuei_step_arr[this.xuexi_step];
            switch (step) {
                case 0:
                    break;
                case 1:
                    break;
                case 2: //单选
                    break;
                case 3:
                    break;
                case 4:
                    break;
                default:
                    break;
            }
            /*let temp_item = {id: item.id,
                      type: 1,
                      mode_type: 1,
                      answer: 0,
                      levels: 0,
                      error_num:0,
                      use_time: 0,
                      huanjie: "",
                      error_type: "",
                      study_type: 1,
                      jilu:[],
                      score:[]
                    };*/
            if (this.err_words) this.err_words.error_num;
            let daan_words = this.daan_lists[this.now_words_index];
            if (daan_words) {
                daan_words.answer = answer ? answer : 0;
                daan_words.is_xuexi = 1; //后台数据筛选用
                //daan_words.right_word_num = 0;
                //daan_words.error_word_num = 0;
                //daan_words.use_time = this.use_time;
                //daan_words.error_num = this.err_words ? (daan_words.error_num+this.err_words.error_num) : daan_words.error_num;
                //let jilu = {answer:answer,type:1,mode_type:1,study_type:1,huanjie:'记忆',use_time:this.use_time,error_type:1};
                //daan_words.jilu.push(jilu);
            }
            //console.log(daan_words);
            ////console.log("now_words.id"+this.now_words.id);
            ////console.log("err_words:");
            ////console.log(this.err_words);
            ////console.log("addRightAnswer");
            ////console.log(daan_words);
        },
        addCaozuLog(answer) {
            let step = this.xuei_step_arr[this.xuexi_step];
            let huanjie = "";
            switch (step) {
                case 0:
                    break;
                case 1:
                    break;
                case 2: //单选
                    huanjie = "词听-中译英";
                    break;
                case 3:
                    huanjie = "词听-英译中";
                    break;
                case 4:
                    break;
                default:
                    break;
            }
            //添加操作记录
            let daan_words = this.daan_lists[this.now_words_index];
            //console.log("添加操作记录");
            //console.log(daan_words);
            //console.log("this.use_time:" + this.use_time);
            if (daan_words) {
                let jilu = {
                    word_id: daan_words.id,
                    answer: answer,
                    type: this.type,
                    mode_type: this.mode_type,
                    study_type: this.studyType,
                    daan: this.now_daan,
                    huanjie: huanjie,
                    use_time: this.use_time,
                    error_type: 0,
                };
                daan_words.jilu.push(jilu);
                if (answer) {
                    daan_words.right_num++;
                } else {
                    daan_words.error_num++;
                }
            }
        },
        /**
         * [study_djs 倒计时]
         * @param  {Number} value [description]
         * @return {[type]}       [description]
         */
        setDjs(value = 0) {
            var that = this;
            if (value > 0) {
                that.djs_count = value;
            }
            if (this.djs_obj) {
                clearInterval(this.djs_obj);
                that.djs_count = this.getDjsCount();
            }
            that.djs_state = 1;
            this.djs_obj = setInterval(function () {
                if (that.djs_state != 1) {
                    clearInterval(that.djs_obj);
                    return false;
                }
                if (that.djs_stop == 0) {
                    if (that.djs_count > 0) {
                        that.djs_count--;
                    } else {
                        if (
                            that.xuei_step_arr[that.xuexi_step] == 2 ||
                            that.xuei_step_arr[that.xuexi_step] == 3
                        ) {
                            that.forget();
                        }
                        clearInterval(that.djs_obj);
                    }
                    //console.log("倒计时：" + that.djs_count);
                }
            }, 1000);
        },
        getDjsCount() {
            let count = 5;
            if (this.nianji_level == 1) {
                count = 10;
            }
            this.djs_count = count;
            return count;
        },
        getRandomInt(min, max) {
            min = Math.ceil(min);
            max = Math.floor(max);
            return Math.floor(Math.random() * (max - min)) + min; //不含最大值，含最小值
        },
        /**
         * [audioPlay 播放音频-α音乐波]
         * @param  {[type]} audioLink [音频链接]
         * @param  {[type]} id        [音频地址索引]
         * @param  {[type]} audio_play[播放状态]
         * @return {[type]}           [description]
         */
        audioPlay(audioLink, id, audio_play) {
            //console.log(this.audioUrl + audioLink);
            var that = this;
            var audio = that.audio_obj[id];
            if (!audio) {
                audio = new Audio();
                audio.src = this.audioUrl + audioLink;
                audio.loop = true;
                that.audio_obj[id] = audio;
            }
            if (audio_play == 0) {
                this.audio_play = 1;
                audio.volume = 0.1;
                audio.play(); //播放
            } else {
                this.audio_play = 0;
                audio.pause(); //暂停
            }
        },
        /**
         * [audioPlay_2 播放音频-单词]
         * @param  {[type]} audioLink [description]
         * @param  {[type]} id        [description]
         * @return {[type]}           [description]
         */
        audioPlay_2(audioLink, id) {
            try {
                //console.log("播放音频");
                // //console.log(audioLink)
                var that = this;
                // if(that.audio_obj[id]){
                //     return false;
                // }
                $.get(audioLink, function (data) {
                    if (data) {
                        var audio = new Audio();
                        // //console.log(audio)
                        that.audio_obj[id] = audio;
                        audio.src = audioLink;
                        audio.play(); //播放
                        //监听播放结束
                        audio.addEventListener(
                            "ended",
                            function () {
                                that.audio_obj[id] = null;
                            },
                            false
                        );
                    }
                });
            } catch (err) {
                //console.log("播放音频失败");
            }
        },
        audioPlay_3(audioLink, id) {
            try {
                //console.log("播放音频");
                var that = this;
                var audio = that.audio_obj[id];
                if (!audio) {
                    audio = new Audio();
                    audio.src = this.audioUrl + audioLink;
                    that.audio_obj[id] = audio;
                }
                //audio.src = this.audioUrl + audioLink;
                audio.play(); //播放
                //监听播放结束
                audio.addEventListener(
                    "ended",
                    function () {
                        that.audio_obj[id] = null;
                    },
                    false
                );
            } catch (err) {
                //console.log("播放音频失败");
            }
        },
        getTime() {
            return new Date().getTime();
        },
        getDiffTime(end_time) {
            if (end_time) {
                this.end_time = end_time;
            } else {
                this.end_time = new Date().getTime();
            }
            let diff_time = (this.end_time - this.start_time) / 1000;
            //console.log("diff_time：" + diff_time);
            this.use_time = diff_time;
            return diff_time;
        },
        addUseTime(step) {
            //添加操作时间
            //添加操作记录
            let daan_words = this.daan_lists[this.now_words_index];
            //console.log("addUseTime:" + this.use_time);
            //console.log("this.use_time:" + this.use_time);
            if (daan_words) {
                if (daan_words.use_time == 0 && step == 1) {
                    daan_words.use_time = this.use_time;
                } else if (daan_words.use_time_sep == 0 && step == 2) {
                    daan_words.use_time = daan_words.use_time + this.use_time;
                    daan_words.use_time_sep = 1;
                }
            }
        },
        addData() {
            if (this.action_status == 0) return;
            this.action_status = 0;
            let danyuan_id = 0;
            if (this.studyType == 1) {
                danyuan_id = this.danyuan.id;
            }

            let data = {
                user_id:this.user.id,
                token: this.token,
                danyuan_id: danyuan_id,
                type: this.type,
                mode_type: this.mode_type,
                daan: this.daan_lists,
                study_type: this.studyType,
            };
            this.axios
                .post("/api/learn/save_study_data", data, {
                    loading: true
                })
                .then((res) => {
                    //console.log(res);
                    if (res.data.status_code == 200) {
                        //this.$message("提交成功");
                        this.emitClose(this.type, 1);
                        //let data = res.data.data
                        //this.$store.commit('login',data)
                        //this.$message('登录成功')
                        //this.$router.push({name:'study_ai_word_ai_word_index'})
                    } else if (res.data.status_code == 202) {
                        //this.$message('用户名或者密码错误')
                    } else {
                        this.$message(res.data.msg);
                    }
                })
                .catch((err) => {
                    //this.loading = false;
                    this.hideLoading();
                    this.action_status = 1;
                });
        },
        getBaoshi() {
            let data = {
                token: this.token,
                course_id: this.course.id,
                danyuan_id: this.danyuan.id,
                type: this.type,
                get_baoshi_type: this.get_baoshi_type,
                scorse: this.wordNum//单词数
            };
            this.axios
                .post("/api/test_center/get_baoshi", data)
                .then((res) => {
                    ////console.log(res);
                    if (res.data.status_code == 200) {
                        this.baoshi_num = res.data.data.integral;
                    } else if (res.data.status_code == 202) {
                    } else {
                        this.$message(res.data.msg);
                    }
                })
                .catch((err) => {
                    this.loading = false;
                });
        },
        ceshiDo() {
            //单元测试
            this.is_goto_test = 1;
            //console.log("this.is_goto_test" + this.is_goto_test);
            this.addData();
        },
        emitClose(id, is_close = 0) {
            this.djs_stop = 1;
            //console.log("emitClose" + this.is_goto_test + "----");
            this.setStepOld();
            this.$emit("close", id, is_close, this.is_goto_test);
        },
        closeDialog() {
            this.last_page_state = 1;
            //this.xuexi_step = 5;
        },
        jixuXuexi() {
            this.djs_stop = 0;
            //this.xuexi_step = this.xuexi_step_old;
            this.last_page_state = 0;
        },
        jixuFuxi() {
            //继续复习
            this.is_goto_test = 2;
            this.addData();
        },
        huiyi_ing() {
            var that = this;
            if (
                this.allLists.length > that.huiyi_show_index &&
                this.xuei_step_arr[this.xuexi_step] == 4
            ) {
                //this.set_progress(this.huiyi_show_index+1,this.all_list.length);
                // this.study_words(that.huiyi_show_index);
                setTimeout(function () {
                    that.huiyi_show_index++;
                    that.huiyi_ing();
                }, 1000);
            }
        },
        conver(index) {
            /*this.show_index = index;
                       if(this.classShow[index]){
                            this.classShow[index] = !this.classShow[index];
                       }else{
                            this.classShow[index] = true;
                       }
                       //console.log(this.classShow[index]);
                      */
            // if (this.$refs.con.classList.contains('convers')) {
            //       this.$refs.con.classList.remove('convers')
            //       //console.log(111);
            //   } else {
            //       this.$refs.con.classList.add('convers');
            //       //console.log
            //   }
            // if (this.classShow) {
            //   this.$ref.con.classList.add('convers');
            //   this.classShow = true;
            // } else {
            //   this.$ref.con.classList.remove('convers');
            //   this.classShow = false;
            // }
            // //console.log(111);
            // this.flag = index;
        },//监听键盘事件
        key_down(event) {
            if (this.key_state != 1) {
                this.key_state = 1;
                this.d_code = event.keyCode;
            }
        },
        key_up(event) {
            ////console.log("code:", code);
            if (event.keyCode == this.d_code) {
                this.key_state = 0;
                this.d_code = "";
            }

            var code = event.keyCode;
            if (this.d_code == 16) {
                code = 1000 + code;
            } else if (this.d_code && this.d_code != 18) {
                //禁止其他组合键(不包括ctrl）
                return false;
            }

            if (code === 49 || code === 50 || code === 51 || code === 52) {
                this.removeAnimate(".li-body-content");
                this.removeAnimate(".chineseCon");
            }

            let step = this.xuei_step_arr[this.xuexi_step];
            //console.log("step" + step);
            if (code == 17) {
                if (step == 1 || step == 2 || step == 3) {
                    if (this.now_words != null) {
                        this.audioPlay_3(this.now_words.audio_link, this.now_words.id);
                    }
                }
            }

            switch (step) {
                case 0:
                    if (code == 13) {
                        //回车
                        //this.start();
                    }
                    break;
                case 1:
                    if (code == 13) {
                        //回车
                        if (this.is_enter == 1) {
                            this.is_enter = 0;
                            if (this.du_num > 0) {
                                this.clkDuNum();
                                setTimeout(() => {
                                    this.is_enter = 1;
                                }, 10);
                            } else {
                                this.duNext();
                                setTimeout(() => {
                                    this.is_enter = 1;
                                }, 10);
                            }

                        }
                    }
                    break;
                case 2:
                    var shiyi_zyy = this.now_words.shiyi_zyy;
                    var xuanxiang = [49, 50, 51, 52]; //1、2、3、4
                    var is_at = 0; //判断是否1，2，3，4
                    xuanxiang.forEach((item) => {
                        if (item === code) {
                            is_at = 1;
                        }
                    });
                    if (is_at) {
                        let i = 0;
                        switch (code) {
                            case 49:
                                i = 0;
                                break;
                            case 50:
                                i = 1;
                                break;
                            case 51:
                                i = 2;
                                break;
                            case 52:
                                i = 3;
                                break;
                            default:
                                break;
                        }
                        if (this.now_words_zyy_daan == 0) {
                            shiyi_zyy.forEach((item, index) => {
                                if (index == i) {
                                    this.checkItemZyy(item, index);
                                }
                            });
                        }
                    }

                    if (code == 13) {
                        //回车
                        if (this.now_words_zyy_daan == 1) {
                            //答案正确
                            this.nextOption();
                        }
                        if (this.now_words_zyy_daan == 2) {
                            //答案错误
                            this.clkBeiYiBei();
                        }
                    }
                    break;
                case 3:
                    var shiyi_zyy = this.now_words.shiyi_zyy;
                    var xuanxiang = [49, 50, 51, 52]; //1、2、3、4
                    var is_at = 0; //判断是否1，2，3，4
                    xuanxiang.forEach((item) => {
                        if (item === code) {
                            is_at = 1;
                        }
                    });
                    if (is_at) {
                        let i = 0;
                        switch (code) {
                            case 49:
                                i = 0;
                                break;
                            case 50:
                                i = 1;
                                break;
                            case 51:
                                i = 2;
                                break;
                            case 52:
                                i = 3;
                                break;
                            default:
                                break;
                        }
                        if (this.now_words_zyy_daan == 0) {
                            shiyi_zyy.forEach((item, index) => {
                                if (index == i) {
                                    this.checkItemZyy(item, index);
                                }
                            });
                        }
                    }

                    if (code == 13) {
                        //回车
                        if (this.now_words_zyy_daan == 1) {
                            //答案正确
                            this.nextOption();
                        }
                        if (this.now_words_zyy_daan == 2) {
                            //答案错误
                            this.clkBeiYiBei();
                        }
                    }
                    break;
                case 8:
                    if (code == 13) {
                        //回车
                        this.start();
                    }
                    break;
                default:
                    return;
            }
        },
    },
    destroyed() {
        this.now_words = null;
        window.removeEventListener("keyup", this.key_up);
        window.removeEventListener("keydown", this.key_down);
    }
};
</script>

<style lang="scss">
@import "@/assets/css/ai_listen.scss";
</style>
